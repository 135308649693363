@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@media print {
  body {
    margin: 0 !important;
  }
}

body {
  font-family: "Lexend Deca", sans-serif !important;
}
::-webkit-scrollbar {
  width: 8px !important;
  height: 10px;
}

.ck-content ol,
.ck-content ul,
.ck-content menu {
  margin-left: 1rem;
  padding: 0;
}

input[type="text"]:disabled {
  background: rgba(0, 0, 0, 0.04);
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #0080a2;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

input[type="file"] {
  color: #666;
}
input[type="checkbox"] {
  color: #666 !important;
  border-radius: 100%;
}

.toggle__dot {
  top: 2px;
  left: 3.5px;
  transition: all 0.3s ease-in-out;
}

input:checked ~ .toggle__dot {
  transform: translateX(100%);
  left: 5px;
  background-color: white;
}

/* pagination css  */

.pagination-container {
  position: absolute;
  bottom: -0.6rem;
  display: flex;
  list-style-type: none;
  right: 28rem;
  /* border: solid 2px #237078; */
  background-color: white;
  width: fit-content;
  border-radius: 9999px;
  padding: 8px;
}

.pagination-container-static {
  right: 2rem;
}

.pagination-container .pagination-item {
  padding: 0 12px;
  height: 28px;
  text-align: center;
  margin: auto 4px;
  color: #b3b3b3;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  color: white;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  /* background-color: rgba(0, 0, 0, 0.04); */
  /* background-color: #237078; */
  color: #237078;
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  /* background-color: rgba(0, 0, 0, 0.08); */
  /* background-color: #237078; */
  color: #237078;
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid #b3b3b3;
  border-top: 0.12em solid #b3b3b3;
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
  /* background-color: #237078; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.rdrCalendarWrapper {
  height: 100% !important;
  padding: 0 !important;
}
.rdrMonthAndYearWrapper {
  margin-top: -15px !important;
}

/* checkbox  */
/* This css is for normalizing styles. You can skip this. */

.check {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.check input {
  display: none;
  border-radius: 100%;
}
.check input:checked + .box {
  background-color: #237078;
}
.check input:checked + .box:after {
  top: 0;
}
.check .box {
  width: 100%;
  height: 100%;
  /* transition: all 1.1s cubic-bezier(0.19, 1, 0.22, 1); */
  border: 2px solid transparent;
  background-color: #e2e2e2;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 100%;
  /* box-shadow: 0 5px rgba(0, 0, 0, 0.2); */
}
.check .box:after {
  width: 70%;
  height: 40%;
  content: "";
  position: absolute;
  border-left: 3px solid;
  border-bottom: 3px solid;
  border-color: white;
  transform: rotate(-45deg) translate3d(0, 0, 0);
  transform-origin: center center;
  left: 0;
  right: 0;
  top: 200%;
  bottom: 10%;
  margin: auto;
}

/* modal  */
.react-responsive-modal-modal {
  padding: 0 !important;
  width: 100% !important;
  height: fit-content;
  border-radius: 20px;
}

/* responsive css  */

@media (min-width: 1500px) {
  .react-responsive-modal-modal {
    height: fit-content;
  }
}

.rdw-editor-main {
  height: 85% !important;
  overflow-y: auto !important;
}

body
  > div.ck-body-wrapper
  > div
  > div.ck.ck-balloon-panel.ck-balloon-panel_position_border-side_right.ck-powered-by-balloon {
  display: none;
}

/* ck-editor */

.ck-content {
  height: 20rem;
}

.sidebar_custom_class {
  overflow: scroll;
}

.sidebar_custom_class::-webkit-scrollbar {
  display: none;
}

.MuiInputBase-root {
  height: 34px;
  border-radius: 100rem !important;
}

.preview-modal {
  min-width: 10rem;
  height: 25rem;
}
@media (min-width: 1500px) {
  .preview-modal {
    height: 35rem;
  }
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #237078;
}

.custom-toast {
  max-width: 500px;
  word-wrap: break-word;
}

.sidebar-animation {
  transition: all 0.3s ease-in;
  overflow: hidden;
  width: 0;
  opacity: 0;
}

.sidebar-open {
  width: 19rem;
  opacity: 1;
}

.MuiPopover-paper {
  height: 15rem;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

/* ck-editor */

.main-container {
  font-family: "Lato";
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.ck-content {
  font-family: "Lato";
  line-height: 1.6;
  word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
  min-width: 795px;
  max-width: 795px;
}

.ck-content h3.category {
  font-family: "Oswald";
  font-size: 20px;
  font-weight: bold;
  color: #555;
  letter-spacing: 10px;
  margin: 0;
  padding: 0;
}

.ck-content h2.document-title {
  font-family: "Oswald";
  font-size: 50px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  border: 0;
}

.ck-content h3.document-subtitle {
  font-family: "Oswald";
  font-size: 20px;
  color: #555;
  margin: 0 0 1em;
  font-weight: bold;
  padding: 0;
}

.ck-content p.info-box {
  --background-size: 30px;
  --background-color: #e91e63;
  padding: 1.2em 2em;
  border: 1px solid var(--background-color);
  background: linear-gradient(
      135deg,
      var(--background-color) 0%,
      var(--background-color) var(--background-size),
      transparent var(--background-size)
    ),
    linear-gradient(
      135deg,
      transparent calc(100% - var(--background-size)),
      var(--background-color) calc(100% - var(--background-size)),
      var(--background-color)
    );
  border-radius: 10px;
  margin: 1.5em 2em;
  box-shadow: 5px 5px 0 #ffe6ef;
}

.ck-content blockquote.side-quote {
  font-family: "Oswald";
  font-style: normal;
  float: right;
  width: 35%;
  position: relative;
  border: 0;
  overflow: visible;
  z-index: 1;
  margin-left: 1em;
}

.ck-content blockquote.side-quote::before {
  content: "“";
  position: absolute;
  top: -37px;
  left: -10px;
  display: block;
  font-size: 200px;
  color: #e7e7e7;
  z-index: -1;
  line-height: 1;
}

.ck-content blockquote.side-quote p {
  font-size: 2em;
  line-height: 1;
}

.ck-content blockquote.side-quote p:last-child:not(:first-child) {
  font-size: 1.3em;
  text-align: right;
  color: #555;
}

.ck-content span.marker {
  background: yellow;
}

.ck-content span.spoiler {
  background: #000;
  color: #000;
}

.ck-content span.spoiler:hover {
  background: #000;
  color: #fff;
}

.ck-content pre.fancy-code {
  border: 0;
  margin-left: 2em;
  margin-right: 2em;
  border-radius: 10px;
}

.ck-content pre.fancy-code::before {
  content: "";
  display: block;
  height: 13px;
  background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
  margin-bottom: 8px;
  background-repeat: no-repeat;
}

.ck-content pre.fancy-code-dark {
  background: #272822;
  color: #fff;
  box-shadow: 5px 5px 0 #0000001f;
}

.ck-content pre.fancy-code-bright {
  background: #dddfe0;
  color: #000;
  box-shadow: 5px 5px 0 #b3b3b3;
}

.editor_container__word-count .ck-word-count {
  color: var(--ck-color-text);
  display: flex;
  height: 20px;
  gap: var(--ck-spacing-small);
  justify-content: flex-end;
  font-size: var(--ck-font-size-base);
  line-height: var(--ck-line-height-base);
  font-family: var(--ck-font-face);
  padding: var(--ck-spacing-small) var(--ck-spacing-standard);
}

.editor-container_include-word-count.editor-container_classic-editor
  .editor_container__word-count {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.editor-container_include-word-count.editor-container_classic-editor
  .editor-container_editor
  .ck-editor
  .ck-editor_editable {
  border-radius: 0;
}
